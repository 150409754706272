/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { useTransition, animated, config } from 'react-spring';
import { useCookies } from 'react-cookie';
import Logger from 'js-logger';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams, useLocation, useHistory,
} from 'react-router-dom';
import { push } from 'connected-react-router';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import CustomLoginFormContainer from './CustomLoginFormContainer';
// import LoginFormContainer from './components/presentational/LoginFormContainer';
// import getAllUrlParams from '../../shared/utils/getAllUrlParams';
// import optimizeImage from '../../../../../shared/utils/imageOptimizer';
import useScript from '../../../../../shared/hooks/useScript';
import { getCustomLoginData, clearUser } from '../../../ducks/loginActions';

const LoginPageContainer = styled('div')`
  display: flex;
  background: ${(props) => props.primaryColor};
  height: 100%!important;
  width: 100%;
  align-items: center;
  justify-content: center;
  a {
    align-self: center;
  }
  .login-image-container {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const LoginWrapper = styled('div')`
  height: auto;
  display: flex;
  z-index: 1000;
`;
const SpinnerWrapper = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const CustomLoginWrapper = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [cookies, setCookies] = useCookies();
  const { portalSlug } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const customLoginInfo = useSelector((state) => state.auth.data.customLoginData);
  const customLoginDataError = useSelector((state) => state.auth.data.customLoginDataError);

  const isCustomLoginDataLoading = useSelector((state) => state.auth.ui.isCustomLoginDataLoading);

  /** originally initializing slides as an array of 12, then forEach slide you are filling then with
 * images from the staging or production database (it will run 12 times and iterate the names
 * of photos baed on the index) then slides are put into an image transition function that will
 * return each image as a part of the slide show with the listed transition properties
*/

  /** const slides = Array(12).fill(0); */
  const slides = Array(1).fill(0);

  slides.forEach((slide, index, slidesArray) => {
    const slidesArrayObject = slidesArray;

    /** commented out code in slidesArray are options for one photo or multiple slides
     * depending on what we decide to offer custon login clients we can easily adapt what
     * we feed the slides array for images
     */
    slidesArrayObject[index] = {
      id: index,
      // url: optimizeImage(
      //   `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/backgrounds/image_login_${index + 1}.jpg`,
      //   {
      //     resize: {
      //       width: 1500, height: 1500, fit: 'inside',
      //     },
      //   },
      // ),
      // url: `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/backgrounds/image_login_${1}.jpg`,
      url: customLoginInfo.portalBackground,
    };
  });
  const imageTransition = useTransition(slides[imageIndex], (item) => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  useScript('https://cdn.ywxi.net/js/1.js');

  const isAuthenticatedv3 = cookies.v2token !== undefined && cookies.v2token !== '0'
    && cookies.accessToken !== undefined && cookies.accessToken !== '0'
    && cookies.session_id !== undefined && cookies.session_id !== '0'
    && cookies.session_key !== undefined && cookies.session_key !== '0';

  useEffect(() => {
    if (!isAuthenticatedv3) {
      // setIsAuthed(false);
      dispatch(clearUser());
      if (window.gapi) {
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (auth2 !== null) {
          auth2.signOut().then(
            Logger.debug('Google has been signed out'),
          );
        }
      }
    }
  }, []);

  useEffect(() => (
    setInterval(() => (
      setImageIndex((state) => (state + 1) % slides.length)
    ), 6000)
  ), []);

  useEffect(() => {
    /** get custom login data using portalSlug from the path
    (using the path info what custom login feature do we need to implement)
    We're using useLocation hook to monitor path changes.
    Listening for path changes with useRouteMatch didn't work. */

    dispatch(getCustomLoginData(portalSlug));
  }, [location]);

  useEffect(() => {
    /** If the custom login data fetch is rejected and we have an error,
     * we hard redirect to the main login page to avoid render bugs. */
    if (customLoginDataError !== null) {
      if (process.env.ENVIRONMENT === 'LOCAL') {
        window.location = `${window.location.protocol}/login`;
      } else {
        window.location = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : ''}app-v3.teambuildr.com/login`;
      }
    }
  }, [customLoginDataError]);

  return (
    <LoginPageContainer primaryColor={customLoginInfo?.primaryColor}>
      <Helmet>
        <title>Sign In | TeamBuildr</title>
      </Helmet>
      {isCustomLoginDataLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <LoginWrapper>
            <CustomLoginFormContainer />
          </LoginWrapper>
          {imageTransition.map(({ item, props, key }) => (
            <animated.div
              key={key}
              className='login-image-container'
              style={{ ...props, backgroundImage: item.url ? `linear-gradient(-20deg, rgba(68, 68, 68, 0.5) 0%, rgba(68, 68, 68, 0.0) 100%), url(${item.url})` : null }}
            />
          ))}
        </>
      )}
    </LoginPageContainer>
  );
};

export default CustomLoginWrapper;
