/* eslint-disable react/prop-types */
import React, {
  memo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import smoothscroll from 'smoothscroll-polyfill';
import findLastIndex from 'lodash.findlastindex';
import moment from 'moment-timezone';
import WeightroomWorkout from './WorkoutContainer';
import WeightroomSessionBreakContainer from '../container/WeightroomSessionBreakContainer';
import ArrowButton from './ButtonArrow';
import { HeaderText } from './Theme';
import { TransitionGlobal } from '../../../../shared/GlobalStyles';
import
{
  fetchWorkouts,
  setAdditionalWorkoutsData,
  clearAdditionalWorkoutsData,
  setShowSessionBreakItems,
  fetchProgramWorkouts,
  fetchImportedIntegrationsByDate,
} from '../../ducks/panelActions';

smoothscroll.polyfill();

const MessageContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 0px 15px;
  h2 {
    line-height: normal;
  }
`;

const WorkoutsContainer = styled('div')`
  overflow: auto;
  flex: 1;
  padding: 0px 20px;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  transition: ${TransitionGlobal};
  z-index: 1;
  &.active {
    /* overflow-y: ${(props) => (props.isScrolling ? 'auto' : 'hidden')}; */
    padding-bottom: 30px;
    .not-active {
      opacity: .2;
    }
    .active {
      ::before {
        opacity: .2;
        height: 32px;
        margin-top: -29px;
      }
      .exercise_sets_reps {
        opacity: 1;
        /* justify-content: space-between; */
      }
    }
  }

  @media screen and (orientation: portrait) {
    padding: 0px 10px;
  }

  button {
    &.prev {
      right: 100px
    }
  }
`;

const WorkoutNav = styled('nav')`
  /* display: flex;
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0px;
  right: 0px;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 0px 50px 0px;
  margin: 30px 20px 0px 0px; */
  z-index: 100;

  button {
    position: absolute;
    right: 30px;
    &:first-of-type {
      top: 60px;
    }
    &:last-of-type {
      bottom: 30px;
    }
  }
`;

const WorkoutList = ({
  completeWorkout,
  closeWorkout,
  decreaseCompletedSets,
  increaseCompletedSets,
  namespace,
  restartQuestions,
  selectQuestion,
  selectWorkout,
  setIsNavigatingSuperset,
}) => {
  const accountCode = useSelector((
    state,
  ) => state.weightRoomView.data.currentUser.accountCode);
  const activeExerType = useSelector((
    state,
  ) => state.weightRoomView[namespace].activeExerType);
  const activeDate = useSelector((
    state,
  ) => state.weightRoomView[namespace].activeDate);
  const activeQuestionIndex = useSelector((
    state,
  ) => state.weightRoomView[namespace].activeQuestionIndex);
  const activeScreens = useSelector((
    state,
  ) => state.weightRoomView.ui.activeScreens);
  const activeWorkout = useSelector((
    state,
  ) => state.weightRoomView[namespace].activeWorkout);
  const activeWorkoutIndex = useSelector((
    state,
  ) => state.weightRoomView[namespace].activeWorkoutIndex);
  const additionalWorkoutsData = useSelector((
    state,
  ) => state.weightRoomView[namespace].additionalWorkoutsData);
  const athleteId = useSelector((
    state,
  ) => state.weightRoomView[namespace].athleteId);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const settings = useSelector((
    state,
  ) => state.weightRoomView.data.currentUser.userSettings);
  const isNavigatingSuperset = useSelector((
    state,
  ) => state.weightRoomView[namespace].isNavigatingSuperset);
  const isProgramView = useSelector((
    state,
  ) => state.weightRoomView[namespace].isProgramView);
  const isWorkoutActive = useSelector((
    state,
  ) => state.weightRoomView[namespace].isWorkoutActive);
  const panelDimensions = useSelector((
    state,
  ) => state.weightRoomView[namespace].panelDimensions);
  const data = useSelector((
    state,
  ) => state.weightRoomView[namespace].workouts);
  const workoutCount = useSelector((state) => state.weightRoomView[namespace].workoutCount);
  const workoutsError = useSelector((
    state,
  ) => state.weightRoomView[namespace].workoutsError);
  const workoutsBySuperset = useSelector((
    state,
  ) => state.weightRoomView[namespace].workoutsBySuperset);
  const currentUser = useSelector((state) => state.weightRoomView.data.currentUser);

  const formik = Array.from({ length: `${data.length}` }, () => useRef(null));
  const questionList = Array.from({ length: `${data.length}` }, () => useRef(null));
  const workoutRef = Array.from({ length: `${data.length}` }, () => useRef(null));
  const workoutWrapperRef = Array.from({ length: `${data.length}` }, () => useRef(null));
  const workoutInner = Array.from({ length: `${data.length}` }, () => useRef(null));
  const entriesContainerRef = Array.from({ length: `${data.length}` }, () => useRef(null));
  const entryRowRef = Array.from({ length: `${data.length}` }, () => useRef(null));
  const workoutsContainer = useRef(null);

  const [navigationDirection, setNavigationDirection] = useState('');

  const { wrvSupersetsEnabled } = settings;

  const dispatch = useDispatch();

  // WORKOUT SCROLLING FUNCTIONS

  const scrollEntries = (direction, activeEntryIndex, workoutIndex) => {
    const entryWidth = entryRowRef[workoutIndex].current.offsetWidth;
    let entryPos;
    if (direction === 'forward') {
      entryPos = entryRowRef[workoutIndex].current
        ? (entryWidth * activeEntryIndex)
        : 0;
    } else {
      entryPos = entryRowRef[workoutIndex].current
        ? (entryWidth * activeEntryIndex) - entryWidth
        : 0;
    }
    if (entriesContainerRef[workoutIndex].current) {
      entriesContainerRef[workoutIndex].current.scroll({ top: 0, left: entryPos, behavior: 'smooth' });
    }
  };

  const scrollWorkout = (workoutIndex) => {
    const workoutPos = workoutWrapperRef[workoutIndex].current
      && (workoutWrapperRef[workoutIndex].current.offsetTop);
    const workoutHeight = workoutWrapperRef[workoutIndex].current
      && (workoutWrapperRef[workoutIndex].current.getBoundingClientRect().height);

    const newPos = workoutHeight + 30 < panelDimensions.height ? (
      workoutPos - (((panelDimensions.height - workoutHeight) / 2)
      )
    ) : (
      workoutPos - 30
    );

    if (workoutsContainer.current) {
      workoutsContainer.current.scroll({ top: newPos, left: 0, behavior: 'smooth' });
    }
  };

  const scrollQuestions = useCallback((prevQIndex, questionIndex) => {
    const workoutEntryOffsetTop = workoutWrapperRef[activeWorkoutIndex].current.offsetTop;

    let questionPos;
    if (prevQIndex < questionIndex) {
      // Moving forward through questions
      const questionRef = questionList[activeWorkoutIndex].current.question[prevQIndex];
      questionPos = questionList[activeWorkoutIndex].current
      && (workoutEntryOffsetTop + questionRef.offsetTop);
    } else {
      // Moving backwards through questions
      const questionRef = questionList[activeWorkoutIndex].current.question[questionIndex];
      questionPos = questionList[activeWorkoutIndex].current
      && (workoutEntryOffsetTop + questionRef.offsetTop);
    }
    const questionHeight = questionList[activeWorkoutIndex].current
      && questionList[activeWorkoutIndex].current.question[prevQIndex].scrollHeight;

    const newPos = questionHeight < panelDimensions.height ? (
      questionPos - (((panelDimensions.height - questionHeight) / 2) - 160)
    ) : (
      questionPos
    );

    if (workoutsContainer.current) {
      workoutsContainer.current.scroll({ top: newPos, left: 0, behavior: 'smooth' });
    }
  }, [activeWorkoutIndex, panelDimensions.height]);

  const isSilkBrowser = /\bSilk\b/.test(navigator.userAgent);

  // WORKOUT CONDITIONS

  const isFormik = (
    activeExerType === 'L'
    || activeExerType === 'C'
    || activeExerType === 'R'
    || activeExerType === 'S'
    || activeExerType === 'H'
    || activeExerType === 'P'
  ) && (!data[activeWorkoutIndex]?.completionCheckbox);

  const isScience = (
    activeExerType === 'S'
  );

  const isCompletionRequired = data[activeWorkoutIndex]?.completionRequired;

  const isLastQuestion = (
    isScience
      ? activeQuestionIndex === (data[activeWorkoutIndex] && (data[activeWorkoutIndex].sets - 1))
      : false
  );

  const isFinalUnlockingQuestion = isLastQuestion && isCompletionRequired;

  const isInSuperset = (
    data[activeWorkoutIndex] && (
      data[activeWorkoutIndex].groupingLetter !== null
    )
  );

  const isSupersetWorkoutActive = (supersetWorkout) => (
    isProgramView
      ? supersetWorkout.saveDataId === data[activeWorkoutIndex].saveDataId
      : supersetWorkout.assignedId === data[activeWorkoutIndex].assignedId
  );

  const activeSuperset = data[activeWorkoutIndex] && (data[activeWorkoutIndex].groupingLetter);

  const activeSupersetArray = workoutsBySuperset && (workoutsBySuperset[activeSuperset]);

  const activeSupersetWorkoutIndex = (isInSuperset && wrvSupersetsEnabled)
    ? activeSupersetArray.findIndex(isSupersetWorkoutActive)
    : null;

  // REFETCH WORKOUTS AFTER QUESTIONNAIRE UNLOCK LOGIC

  useEffect(() => {
    if (additionalWorkoutsData.refreshWorkoutAfterCompleted
      && !additionalWorkoutsData.isRefetchingWorkouts) {
      batch(() => {
        if (isProgramView) {
          dispatch(fetchProgramWorkouts(
            accountCode,
            athleteId,
            namespace,
            currentSelectedProgram,
            currentNonDateDay.trueDayNum,
            currentNonDateDay,
            true,
          ));
        } else {
          dispatch(fetchWorkouts(accountCode, athleteId, namespace, activeDate, true));
        }
        dispatch(setAdditionalWorkoutsData(
          {
            isRefetchingWorkouts: true,
            isFinalUnlockingQuestion,
          },
        ));
      });
      restartQuestions(namespace);
    }
  }, [additionalWorkoutsData]);

  useEffect(() => {
    const formattedActiveDate = moment(activeDate).format('YYYY-MM-DD');

    if (currentUser.admin && athleteId) {
      dispatch(
        fetchImportedIntegrationsByDate(namespace, currentUser, athleteId, formattedActiveDate),
      );
    }
  }, [currentUser, activeDate]);

  // Make sure to scroll to the correct workout if it was the last workout on the list
  useEffect(() => {
    if (additionalWorkoutsData.refreshWorkoutAfterCompleted
      && additionalWorkoutsData.isRefetchingWorkouts) {
      // If the unlocking question was the last workout item on the previous list
      // skip to the next workout in the updated list
      if (additionalWorkoutsData.isFinalUnlockingQuestion) {
        // If the next workout is a BR, expand it and skip to next workout
        if (data[activeWorkoutIndex + 1].type === 'BR') {
          // Find the next workout that isn't a BR and go to it
          // (in case the next BR is empty)
          let nextIndex;
          // eslint-disable-next-line no-plusplus
          for (let i = activeWorkoutIndex + 1, n = data.length - 1; i <= n; i++) {
            if (data[i].type !== 'BR') {
              nextIndex = i;
              break;
            }
          }
          // Open the next BR before scrolling
          if (data[nextIndex - 1].showSessionBreakItems === 0) {
            dispatch(setShowSessionBreakItems(namespace, nextIndex - 1, 1));
          }
          const nextWorkout = data[nextIndex];
          setTimeout(() => {
            dispatch(clearAdditionalWorkoutsData());
            selectWorkout(
              nextWorkout.assignedId || nextWorkout.saveDataId,
              nextIndex,
              nextWorkout.exercise?.id,
              nextWorkout.type,
              namespace,
            );
          }, 500);
          setTimeout(() => scrollWorkout(nextIndex), isSilkBrowser ? 1200 : 800);
        } else {
          const nextWorkout = data[activeWorkoutIndex + 1];
          setTimeout(() => {
            dispatch(clearAdditionalWorkoutsData());
            selectWorkout(
              nextWorkout.assignedId || nextWorkout.saveDataId,
              activeWorkoutIndex + 1,
              nextWorkout.exercise?.id,
              nextWorkout.type,
              namespace,
            );
          }, 500);
          setTimeout(() => scrollWorkout(activeWorkoutIndex + 1), isSilkBrowser ? 1200 : 800);
        }
      } else {
        setTimeout(() => {
          dispatch(clearAdditionalWorkoutsData());
          scrollWorkout(activeWorkoutIndex);
        }, 500);
      }
    }
  }, [workoutCount]);

  // NAVIGATE SUPERSET LOGIC (DATE BASED)
  const navigateSuperset = () => {
    if (navigationDirection === 'forward') {
      console.log(data[activeWorkoutIndex]);
      /*
      If navigation is forward, loop through the superset array starting after the active superset
      workout index, and find the next incomplete workout.
      */
      const offset = activeSupersetWorkoutIndex + 1;
      for (let i = 0; i < activeSupersetArray.length; i += 1) {
        const pointer = (i + offset) % activeSupersetArray.length;
        // Check if the superset workout is incomplete
        if (activeSupersetArray[pointer].completed_sets !== activeSupersetArray[pointer].sets) {
          // If the next incomplete workout is the current one, don't do anything
          if (activeSupersetArray[pointer].assignedId === data[activeWorkoutIndex].assignedId) {
            setTimeout(() => scrollEntries(
              navigationDirection,
              activeSupersetArray[pointer].completed_sets,
              activeWorkoutIndex,
            ), 10);
            break;
          } else {
            // If the next incomplete workout is not the current one, go to that workout
            console.log('Go to this workout', activeSupersetArray[pointer]);
            const nextIncompleteWorkout = activeSupersetArray[pointer];
            const isNextWorkout = (workout) => (
              nextIncompleteWorkout.assignedId === workout.assignedId
            );
            const nextIncompleteWorkoutIndex = data.findIndex(isNextWorkout);
            selectWorkout(
              nextIncompleteWorkout.assignedId,
              nextIncompleteWorkoutIndex,
              nextIncompleteWorkout.exercise.id,
              nextIncompleteWorkout.type,
              namespace,
            );
            setTimeout(() => scrollWorkout(nextIncompleteWorkoutIndex), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              navigationDirection,
              nextIncompleteWorkout.completed_sets,
              nextIncompleteWorkoutIndex,
            ), 400);
            break;
          }
        } else if (i + 1 === activeSupersetArray.length) {
          console.log('This workout is complete', pointer, i, activeSupersetArray[pointer]);
          /*
            If all superset workouts are complete, and it's not the last superset,
            then go to the next workout outside of the superset
          */
          const isLastSupersetWorkoutIndex = (workout) => (
            activeSupersetArray[activeSupersetArray.length - 1].assignedId === workout.assignedId
          );
          const lastSupersetWorkoutIndex = data.findIndex(isLastSupersetWorkoutIndex);
          const lastSuperset = Object.keys(workoutsBySuperset).reverse()[0];

          /*
            If it is the last superset, and there isn't a workout
            after the last workout in the superset, complete that day's workout
          */
          if (data[lastSupersetWorkoutIndex + 1] === undefined) {
            if (activeSuperset === lastSuperset) {
              console.log('No Workout!');
              completeWorkout(namespace);
            } else {
              /*
                You are at the end of the workout, but this wasn't the last workout
                or last superset, just gonna return to workout list and user can choose
                any missed workouts
              */
              closeWorkout(namespace);
            }
          } else {
            console.log('Workout after superset', data[lastSupersetWorkoutIndex + 1]);
            const nextWorkoutOutsideSuperset = data[lastSupersetWorkoutIndex + 1];
            // If next workout outside Superset is BR, expand it and skip to next workout
            if (nextWorkoutOutsideSuperset.type === 'BR') {
              // Find the next workout that isn't a BR and go to it
              let nextIndex;
              // eslint-disable-next-line no-plusplus
              for (let k = lastSupersetWorkoutIndex + 1, n = data.length - 1; k <= n; k++) {
                if (data[k].type !== 'BR') {
                  nextIndex = k;
                  break;
                }
              }
              // Open the next BR before scrolling
              if (data[nextIndex - 1].showSessionBreakItems === 0) {
                dispatch(setShowSessionBreakItems(namespace, nextIndex - 1, 1));
              }
              if (workoutRef[nextIndex].current) {
                setTimeout(() => selectWorkout(
                  parseInt(workoutRef[nextIndex].current.props.id, 10),
                  parseInt(workoutRef[nextIndex].current.props.index, 10),
                  parseInt(workoutRef[nextIndex].current.props.exerId, 10),
                  workoutRef[nextIndex].current.props.exerType,
                  namespace,
                ), isSilkBrowser ? 700 : 500);
                setTimeout(() => scrollWorkout(nextIndex), isSilkBrowser ? 1200 : 800);
              }
            } else {
              // Handle next navigation outside Superset normally
              selectWorkout(
                nextWorkoutOutsideSuperset.assignedId,
                lastSupersetWorkoutIndex + 1,
                nextWorkoutOutsideSuperset.exercise?.id,
                nextWorkoutOutsideSuperset.type,
                namespace,
              );
              setTimeout(() => scrollWorkout(
                lastSupersetWorkoutIndex + 1,
              ), isSilkBrowser ? 1200 : 800);
            }
          }
        }
      }
      setIsNavigatingSuperset(namespace, false);
    } else {
      /*
        If navigation direction is backwards, find the workout with the largest
        completed sets in the current superset, and calculate wether the largest two are equal.
      */
      const allEqual = (arr) => (
        arr.every(
          (v) => v.completed_sets === arr[0].completed_sets,
        )
      );
      const descendingActiveSupersetArray = Array.from(activeSupersetArray).sort(
        (a, b) => (b.completed_sets - a.completed_sets),
      );
      const slicedDescendingSupersetArray = descendingActiveSupersetArray.slice(0, 2);
      const isEqual = allEqual(slicedDescendingSupersetArray);

      /*
       If the largest completed sets is 0, and it's not the first workout in the superset
       then just go to the previous workout.
      */
      if (slicedDescendingSupersetArray[0].completed_sets === 0
        && activeSupersetArray[0].assignedId !== data[activeWorkoutIndex].assignedId) {
        const prevWorkoutIndex = activeWorkoutIndex - 1;
        const prevWorkout = data[activeWorkoutIndex];
        console.log('All completed sets are 0!', prevWorkoutIndex, prevWorkout, slicedDescendingSupersetArray[0]);

        selectWorkout(
          prevWorkout.assignedId,
          prevWorkoutIndex,
          prevWorkout.exercise.id,
          prevWorkout.type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          prevWorkoutIndex,
        ), isSilkBrowser ? 1200 : 800);

      /*
        If the current workout is the first superset workout,
        and the completed sets is 0, find the previous workout to go to.
      */
      } else if ((activeSupersetArray[0].assignedId === data[activeWorkoutIndex].assignedId)
        && (activeSupersetArray[activeSupersetWorkoutIndex].completed_sets <= 0)
      ) {
        const isFirstSupersetWorkoutIndex = (workout) => (
          activeSupersetArray[0].assignedId === workout.assignedId
        );
        const firstSupersetWorkoutIndex = data.findIndex(isFirstSupersetWorkoutIndex);
        const previousWorkoutOutsideSuperset = data[firstSupersetWorkoutIndex - 1];

        /*
          If the previous workout outside the superset is in a superset,
          find the previous superset's largest completed_sets
        */
        if (previousWorkoutOutsideSuperset.groupingLetter !== null) {
          console.log('Previous Workout is in Superset');
          const previousSuperset = previousWorkoutOutsideSuperset.groupingLetter;
          const previousSupersetArray = workoutsBySuperset[previousSuperset];
          const descendingPreviousSupersetArray = Array.from(previousSupersetArray).sort(
            (a, b) => (b.completed_sets - a.completed_sets),
          );
          const slicedDescendingPreviousSupersetArray = descendingPreviousSupersetArray.slice(0, 2);
          const isPreviousEqual = allEqual(slicedDescendingPreviousSupersetArray);

          /*
            If there is only one superset workout with the
            largest completed sets in the previous superset,
            go to the largest superset workout in the previous superset.
          */
          if (!isPreviousEqual) {
            console.log('1st, Not Equal');
            const largestSupersetWorkout = descendingPreviousSupersetArray[0];
            const isLargestWorkout = (workout) => (
              descendingPreviousSupersetArray[0].assignedId === workout.assignedId
            );
            const largestWorkoutIndex = data.findIndex(isLargestWorkout);
            const largestSupersetWorkoutIndex = previousSupersetArray.findIndex(isLargestWorkout);
            console.log('Previous is not equal', previousSuperset, largestSupersetWorkoutIndex, previousSupersetArray);

            decreaseCompletedSets(namespace, previousSuperset, largestSupersetWorkoutIndex);
            selectWorkout(
              largestSupersetWorkout.assignedId,
              largestWorkoutIndex,
              largestSupersetWorkout.exercise.id,
              largestSupersetWorkout.type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              largestWorkoutIndex,
            ), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              navigationDirection,
              largestSupersetWorkout.completed_sets - 1,
              largestWorkoutIndex,
            ), 10);

          /*
            If there are multiple superset workouts with the
            largest completed sets in the previous superset,
            find the last superset workout index with the same completed sets and go to it
          */
          } else if (isPreviousEqual) {
            console.log('1st, Equal', isPreviousEqual);
            const lastIndexOfSameSets = findLastIndex(descendingPreviousSupersetArray, ['completed_sets', slicedDescendingPreviousSupersetArray[0].completed_sets]);
            const isLastSupersetWorkoutOfSameSets = (workout) => (
              descendingPreviousSupersetArray[lastIndexOfSameSets].assignedId === workout.assignedId
            );
            const lastWorkoutOfSameSetsIndex = data.findIndex(isLastSupersetWorkoutOfSameSets);
            const lastWorkoutOfSameSets = data[lastWorkoutOfSameSetsIndex];
            const lastSupersetWorkoutOfSameSetsIndex = (
              previousSupersetArray.findIndex(isLastSupersetWorkoutOfSameSets)
            );
            const lastSupersetWorkoutOfSameSets = (
              previousSupersetArray[lastSupersetWorkoutOfSameSetsIndex]
            );

            console.log('Previous is equal', previousWorkoutOutsideSuperset, previousSuperset, lastSupersetWorkoutOfSameSetsIndex, lastWorkoutOfSameSets, activeSupersetArray);
            console.log('entry variables', lastSupersetWorkoutOfSameSets.completed_sets - 1, lastSupersetWorkoutOfSameSets, lastWorkoutOfSameSetsIndex);

            decreaseCompletedSets(
              namespace,
              previousSuperset,
              lastSupersetWorkoutOfSameSetsIndex === -1 ? 0 : lastSupersetWorkoutOfSameSetsIndex,
            );
            selectWorkout(
              lastWorkoutOfSameSets.assignedId,
              lastWorkoutOfSameSetsIndex,
              lastWorkoutOfSameSets.exercise.id,
              lastWorkoutOfSameSets.type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              lastWorkoutOfSameSetsIndex,
            ), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              navigationDirection,
              lastSupersetWorkoutOfSameSets.completed_sets - 1,
              lastWorkoutOfSameSetsIndex,
            ), 10);
          }

        /*
          If the previous workout outside the superset is not a superset,
          go directly to the previous workout.
        */
        } else {
          console.log('Previous Workout Not in Superset');
          // If prev workout outside Superset is a BR, expand it and skip to next workout
          if (previousWorkoutOutsideSuperset.type === 'BR') {
            // Find the next workout that isn't a BR and go to it
            let prevIndex;
            // eslint-disable-next-line no-plusplus
            for (let i = firstSupersetWorkoutIndex - 1; i >= 0; i--) {
              if (data[i].type !== 'BR') {
                prevIndex = i;
                break;
              }
            }
            // Find the BR that the workout belongs to in order to open it
            let brIndex;
            // eslint-disable-next-line no-plusplus
            for (let j = prevIndex; j >= 0; j--) {
              if (data[j].type === 'BR') {
                brIndex = j;
                break;
              }
            }
            // Open the next BR before scrolling
            if (brIndex !== null && data[brIndex].showSessionBreakItems === 0) {
              dispatch(setShowSessionBreakItems(namespace, brIndex, 1));
            }
            if (workoutRef[prevIndex].current) {
              setTimeout(() => selectWorkout(
                parseInt(workoutRef[prevIndex].current.props.id, 10),
                parseInt(workoutRef[prevIndex].current.props.index, 10),
                parseInt(workoutRef[prevIndex].current.props.exerId, 10),
                workoutRef[prevIndex].current.props.exerType,
                namespace,
              ), isSilkBrowser ? 700 : 500);
              setTimeout(() => scrollWorkout(prevIndex), isSilkBrowser ? 1200 : 800);
            }
          } else {
            // Handle prev navigation outside superset normally
            selectWorkout(
              previousWorkoutOutsideSuperset.assignedId,
              firstSupersetWorkoutIndex - 1,
              previousWorkoutOutsideSuperset.exercise?.id,
              previousWorkoutOutsideSuperset.type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              firstSupersetWorkoutIndex - 1,
            ), isSilkBrowser ? 1200 : 800);
          }
        }

      /*
        If the largest completed superset workout is the current workout,
        just decrease the completed sets.
      */
      } else if (
        (descendingActiveSupersetArray[0].assignedId === data[activeWorkoutIndex].assignedId)
        && (!isEqual)
      ) {
        console.log('Its the current workout!', activeSupersetWorkoutIndex);
        decreaseCompletedSets(namespace, activeSuperset, activeSupersetWorkoutIndex);

      /*
        If there are multiple workouts with the largest completed sets,
        find the last index of those multiple workouts and go to it.
      */
      } else if (isEqual) {
        console.log('How Many Equal?', allEqual(descendingActiveSupersetArray));
        const lastIndexOfSameSets = findLastIndex(descendingActiveSupersetArray, ['completed_sets', slicedDescendingSupersetArray[0].completed_sets]);
        console.log('Last workout with highest number of sets', lastIndexOfSameSets, slicedDescendingSupersetArray[0].completed_sets);
        const isLastSupersetWorkoutOfSameSets = (workout) => (
          descendingActiveSupersetArray[lastIndexOfSameSets].assignedId === workout.assignedId
        );
        const lastWorkoutOfSameSetsIndex = data.findIndex(isLastSupersetWorkoutOfSameSets);
        const lastWorkoutOfSameSets = data[lastWorkoutOfSameSetsIndex];
        const lastSupersetWorkoutOfSameSetsIndex = (
          activeSupersetArray.findIndex(isLastSupersetWorkoutOfSameSets)
        );

        decreaseCompletedSets(namespace, activeSuperset, lastSupersetWorkoutOfSameSetsIndex);
        selectWorkout(
          lastWorkoutOfSameSets.assignedId,
          lastWorkoutOfSameSetsIndex,
          lastWorkoutOfSameSets.exercise.id,
          lastWorkoutOfSameSets.type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          lastWorkoutOfSameSetsIndex,
        ), isSilkBrowser ? 1200 : 800);
        setTimeout(() => scrollEntries(
          navigationDirection,
          descendingActiveSupersetArray[lastSupersetWorkoutOfSameSetsIndex].completed_sets - 1,
          lastWorkoutOfSameSetsIndex,
        ), 10);
      /*
        If there are no multiple workouts with the largest completed sets,
        and it's not the current workout, go to the largest workout.
      */
      } else {
        const isWorkoutWithLargestCompletedSets = (workout) => (
          descendingActiveSupersetArray[0].assignedId === workout.assignedId
        );
        const prevWorkoutIndex = data.findIndex(isWorkoutWithLargestCompletedSets);
        const prevWorkout = descendingActiveSupersetArray[0];
        const prevWorkoutSupersetIndex = (
          activeSupersetArray.findIndex(isWorkoutWithLargestCompletedSets)
        );
        console.log('Am I actually running?', prevWorkoutIndex, prevWorkout, prevWorkoutSupersetIndex);
        decreaseCompletedSets(namespace, activeSuperset, prevWorkoutSupersetIndex);
        selectWorkout(
          prevWorkout.assignedId,
          prevWorkoutIndex,
          prevWorkout.exercise.id,
          prevWorkout.type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          prevWorkoutIndex,
        ), isSilkBrowser ? 1200 : 800);
        setTimeout(() => scrollEntries(
          navigationDirection,
          prevWorkout.completed_sets - 1,
          prevWorkoutIndex,
        ), 10);
      }
      setIsNavigatingSuperset(namespace, false);
    }
  };

  // NAVIGATE SUPERSET LOGIC (NON-DATE BASED)
  const navigateSupersetProgram = () => {
    if (navigationDirection === 'forward') {
      /*
        If navigation is forward, loop through the superset array starting after the active superset
        workout index, and find the next incomplete workout.
      */
      const offset = activeSupersetWorkoutIndex + 1;
      for (let i = 0; i < activeSupersetArray.length; i += 1) {
        const pointer = (i + offset) % activeSupersetArray.length;
        // Check if the superset workout is incomplete
        if (activeSupersetArray[pointer].completed_sets !== activeSupersetArray[pointer].sets) {
          // If the next incomplete workout is the current one, don't do anything
          if (activeSupersetArray[pointer].saveDataId === data[activeWorkoutIndex].saveDataId) {
            setTimeout(() => scrollEntries(
              navigationDirection,
              activeSupersetArray[pointer].completed_sets,
              activeWorkoutIndex,
            ), 10);
            break;
          } else {
            // If the next incomplete workout is not the current one, go to that workout
            console.log('Go to this workout', activeSupersetArray[pointer]);
            const nextIncompleteWorkout = activeSupersetArray[pointer];
            const isNextWorkout = (workout) => (
              nextIncompleteWorkout.saveDataId === workout.saveDataId
            );
            const nextIncompleteWorkoutIndex = data.findIndex(isNextWorkout);
            selectWorkout(
              nextIncompleteWorkout.saveDataId,
              nextIncompleteWorkoutIndex,
              nextIncompleteWorkout.exercise.id,
              nextIncompleteWorkout.type,
              namespace,
            );
            setTimeout(() => scrollWorkout(nextIncompleteWorkoutIndex), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              navigationDirection,
              nextIncompleteWorkout.completed_sets,
              nextIncompleteWorkoutIndex,
            ), 400);
            break;
          }
        } else if (i + 1 === activeSupersetArray.length) {
          console.log('This workout is complete', pointer, i, activeSupersetArray[pointer]);
          /*
              If all superset workouts are complete, and it's not the last superset,
              then go to the next workout outside of the superset
            */
          const isLastSupersetWorkoutIndex = (workout) => (
            activeSupersetArray[activeSupersetArray.length - 1].saveDataId === workout.saveDataId
          );
          const lastSupersetWorkoutIndex = data.findIndex(isLastSupersetWorkoutIndex);
          const lastSuperset = Object.keys(workoutsBySuperset).reverse()[0];

          /*
              If it is the last superset, and there isn't a workout
              after the last workout in the superset, complete that day's workout
            */
          if (
            (activeSuperset === lastSuperset)
                && (data[lastSupersetWorkoutIndex + 1] === undefined)
          ) {
            console.log('Workout after superset', data[lastSupersetWorkoutIndex + 1]);
            // if (data[lastSupersetWorkoutIndex + 1] === undefined) {
            console.log('No Workout!');
            completeWorkout(namespace);
          } else {
            const nextWorkoutOutsideSuperset = data[lastSupersetWorkoutIndex + 1];
            // If next workout outside Superset is BR, expand it and skip to next workout
            if (nextWorkoutOutsideSuperset.type === 'BR') {
              // Find the next workout that isn't a BR and go to it
              let nextIndex;
              // eslint-disable-next-line no-plusplus
              for (let k = lastSupersetWorkoutIndex + 1, n = data.length - 1; k <= n; k++) {
                if (data[k].type !== 'BR') {
                  nextIndex = k;
                  break;
                }
              }
              // Open the next BR before scrolling
              if (data[nextIndex - 1].showSessionBreakItems === 0) {
                dispatch(setShowSessionBreakItems(namespace, nextIndex - 1, 1));
              }
              if (workoutRef[nextIndex].current) {
                setTimeout(() => selectWorkout(
                  parseInt(workoutRef[nextIndex].current.props.id, 10),
                  parseInt(workoutRef[nextIndex].current.props.index, 10),
                  parseInt(workoutRef[nextIndex].current.props.exerId, 10),
                  workoutRef[nextIndex].current.props.exerType,
                  namespace,
                ), isSilkBrowser ? 700 : 500);
                setTimeout(() => scrollWorkout(nextIndex), isSilkBrowser ? 1200 : 800);
              }
            } else {
              // Handle next navigation outside Superset normally
              selectWorkout(
                nextWorkoutOutsideSuperset.saveDataId,
                lastSupersetWorkoutIndex + 1,
                nextWorkoutOutsideSuperset.exercise?.id,
                nextWorkoutOutsideSuperset.type,
                namespace,
              );
              setTimeout(() => scrollWorkout(
                lastSupersetWorkoutIndex + 1,
              ), isSilkBrowser ? 1200 : 800);
              // console.log('Go to the first workout outside of the group');
            }
          }
        }
      }
      setIsNavigatingSuperset(namespace, false);
    } else {
      /*
          If navigation direction is backwards, find the workout with the largest
          completed sets in the current superset, and calculate wether the largest two are equal.
        */
      const allEqual = (arr) => (
        arr.every(
          (v) => v.completed_sets === arr[0].completed_sets,
        )
      );
      const descendingActiveSupersetArray = Array.from(activeSupersetArray).sort(
        (a, b) => (b.completed_sets - a.completed_sets),
      );
      const slicedDescendingSupersetArray = descendingActiveSupersetArray.slice(0, 2);
      const isEqual = allEqual(slicedDescendingSupersetArray);

      /*
         If the largest completed sets is 0, and it's not the first workout in the superset
         then just go to the previous workout.
        */
      if (slicedDescendingSupersetArray[0].completed_sets === 0
        && activeSupersetArray[0].saveDataId !== data[activeWorkoutIndex].saveDataId) {
        const prevWorkoutIndex = activeWorkoutIndex - 1;
        const prevWorkout = data[activeWorkoutIndex];
        console.log('All completed sets are 0!', prevWorkoutIndex, prevWorkout, slicedDescendingSupersetArray[0]);

        selectWorkout(
          prevWorkout.saveDataId,
          prevWorkoutIndex,
          prevWorkout.exercise.id,
          prevWorkout.type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          prevWorkoutIndex,
        ), isSilkBrowser ? 1200 : 800);

        /*
          If the current workout is the first superset workout,
          and the completed sets is 0, find the previous workout to go to.
        */
      } else if ((activeSupersetArray[0].saveDataId === data[activeWorkoutIndex].saveDataId)
          && (activeSupersetArray[activeSupersetWorkoutIndex].completed_sets <= 0)
      ) {
        const isFirstSupersetWorkoutIndex = (workout) => (
          activeSupersetArray[0].saveDataId === workout.saveDataId
        );
        const firstSupersetWorkoutIndex = data.findIndex(isFirstSupersetWorkoutIndex);
        const previousWorkoutOutsideSuperset = data[firstSupersetWorkoutIndex - 1];

        /*
            If the previous workout outside the superset is in a superset,
            find the previous superset's largest completed_sets
          */
        if (previousWorkoutOutsideSuperset.groupingLetter !== null) {
          console.log('Previous Workout is in Superset');
          const previousSuperset = previousWorkoutOutsideSuperset.groupingLetter;
          const previousSupersetArray = workoutsBySuperset[previousSuperset];
          const descendingPreviousSupersetArray = Array.from(previousSupersetArray).sort(
            (a, b) => (b.completed_sets - a.completed_sets),
          );
          const slicedDescendingPreviousSupersetArray = descendingPreviousSupersetArray.slice(0, 2);
          const isPreviousEqual = allEqual(slicedDescendingPreviousSupersetArray);

          /*
              If there is only one superset workout with the
              largest completed sets in the previous superset,
              go to the largest superset workout in the previous superset.
            */
          if (!isPreviousEqual) {
            console.log('1st, Not Equal');
            const largestSupersetWorkout = descendingPreviousSupersetArray[0];
            const isLargestWorkout = (workout) => (
              descendingPreviousSupersetArray[0].saveDataId === workout.saveDataId
            );
            const largestWorkoutIndex = data.findIndex(isLargestWorkout);
            const largestSupersetWorkoutIndex = previousSupersetArray.findIndex(isLargestWorkout);
            console.log('Previous is not equal', previousSuperset, largestSupersetWorkoutIndex, previousSupersetArray);

            decreaseCompletedSets(namespace, previousSuperset, largestSupersetWorkoutIndex);
            selectWorkout(
              largestSupersetWorkout.saveDataId,
              largestWorkoutIndex,
              largestSupersetWorkout.exercise.id,
              largestSupersetWorkout.type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              largestWorkoutIndex,
            ), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              navigationDirection,
              largestSupersetWorkout.completed_sets - 1,
              largestWorkoutIndex,
            ), 10);

            /*
              If there are multiple superset workouts with the
              largest completed sets in the previous superset,
              find the last superset workout index with the same completed sets and go to it
            */
          } else if (isPreviousEqual) {
            console.log('1st, Equal', isPreviousEqual);
            const lastIndexOfSameSets = findLastIndex(descendingPreviousSupersetArray, ['completed_sets', slicedDescendingPreviousSupersetArray[0].completed_sets]);
            const isLastSupersetWorkoutOfSameSets = (workout) => (
              descendingPreviousSupersetArray[lastIndexOfSameSets].saveDataId === workout.saveDataId
            );
            const lastWorkoutOfSameSetsIndex = data.findIndex(isLastSupersetWorkoutOfSameSets);
            const lastWorkoutOfSameSets = data[lastWorkoutOfSameSetsIndex];
            const lastSupersetWorkoutOfSameSetsIndex = (
              previousSupersetArray.findIndex(isLastSupersetWorkoutOfSameSets)
            );
            const lastSupersetWorkoutOfSameSets = (
              previousSupersetArray[lastSupersetWorkoutOfSameSetsIndex]
            );

            console.log('Previous is equal', previousWorkoutOutsideSuperset, previousSuperset, lastSupersetWorkoutOfSameSetsIndex, lastWorkoutOfSameSets, activeSupersetArray);
            console.log('entry variables', lastSupersetWorkoutOfSameSets.completed_sets - 1, lastSupersetWorkoutOfSameSets, lastWorkoutOfSameSetsIndex);

            decreaseCompletedSets(
              namespace,
              previousSuperset,
              lastSupersetWorkoutOfSameSetsIndex === -1 ? 0 : lastSupersetWorkoutOfSameSetsIndex,
            );
            selectWorkout(
              lastWorkoutOfSameSets.saveDataId,
              lastWorkoutOfSameSetsIndex,
              lastWorkoutOfSameSets.exercise.id,
              lastWorkoutOfSameSets.type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              lastWorkoutOfSameSetsIndex,
            ), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              navigationDirection,
              lastSupersetWorkoutOfSameSets.completed_sets - 1,
              lastWorkoutOfSameSetsIndex,
            ), 10);
          }

          /*
            If the previous workout outside the superset is not a superset,
            go directly to the previous workout.
          */
        } else {
          console.log('Previous Workout Not in Superset');
          // If prev workout outside Superset is a BR, expand it and skip to next workout
          if (previousWorkoutOutsideSuperset.type === 'BR') {
            // Find the next workout that isn't a BR and go to it
            let prevIndex;
            // eslint-disable-next-line no-plusplus
            for (let i = firstSupersetWorkoutIndex - 1; i >= 0; i--) {
              if (data[i].type !== 'BR') {
                prevIndex = i;
                break;
              }
            }
            // Find the BR that the workout belongs to in order to open it
            let brIndex;
            // eslint-disable-next-line no-plusplus
            for (let j = prevIndex; j >= 0; j--) {
              if (data[j].type === 'BR') {
                brIndex = j;
                break;
              }
            }
            // Open the next BR before scrolling
            if (brIndex !== null && data[brIndex].showSessionBreakItems === 0) {
              dispatch(setShowSessionBreakItems(namespace, brIndex, 1));
            }
            if (workoutRef[prevIndex].current) {
              setTimeout(() => selectWorkout(
                parseInt(workoutRef[prevIndex].current.props.id, 10),
                parseInt(workoutRef[prevIndex].current.props.index, 10),
                parseInt(workoutRef[prevIndex].current.props.exerId, 10),
                workoutRef[prevIndex].current.props.exerType,
                namespace,
              ), isSilkBrowser ? 700 : 500);
              setTimeout(() => scrollWorkout(prevIndex), isSilkBrowser ? 1200 : 800);
            }
          } else {
            // Handle prev navigation outside superset normally
            selectWorkout(
              previousWorkoutOutsideSuperset.saveDataId,
              firstSupersetWorkoutIndex - 1,
              previousWorkoutOutsideSuperset.exercise?.id,
              previousWorkoutOutsideSuperset.type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              firstSupersetWorkoutIndex - 1,
            ), isSilkBrowser ? 1200 : 800);
          }
        }

        /*
          If the largest completed superset workout is the current workout,
          just decrease the completed sets.
        */
      } else if (
        (descendingActiveSupersetArray[0].saveDataId === data[activeWorkoutIndex].saveDataId)
          && (!isEqual)
      ) {
        console.log('Its the current workout!', activeSupersetWorkoutIndex);
        decreaseCompletedSets(namespace, activeSuperset, activeSupersetWorkoutIndex);

        /*
          If there are multiple workouts with the largest completed sets,
          find the last index of those multiple workouts and go to it.
        */
      } else if (isEqual) {
        console.log('How Many Equal?', allEqual(descendingActiveSupersetArray));
        const lastIndexOfSameSets = findLastIndex(descendingActiveSupersetArray, ['completed_sets', slicedDescendingSupersetArray[0].completed_sets]);
        console.log('Last workout with highest number of sets', lastIndexOfSameSets, slicedDescendingSupersetArray[0].completed_sets);
        const isLastSupersetWorkoutOfSameSets = (workout) => (
          descendingActiveSupersetArray[lastIndexOfSameSets].saveDataId === workout.saveDataId
        );
        const lastWorkoutOfSameSetsIndex = data.findIndex(isLastSupersetWorkoutOfSameSets);
        const lastWorkoutOfSameSets = data[lastWorkoutOfSameSetsIndex];
        const lastSupersetWorkoutOfSameSetsIndex = (
          activeSupersetArray.findIndex(isLastSupersetWorkoutOfSameSets)
        );

        decreaseCompletedSets(namespace, activeSuperset, lastSupersetWorkoutOfSameSetsIndex);
        selectWorkout(
          lastWorkoutOfSameSets.saveDataId,
          lastWorkoutOfSameSetsIndex,
          lastWorkoutOfSameSets.exercise.id,
          lastWorkoutOfSameSets.type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          lastWorkoutOfSameSetsIndex,
        ), isSilkBrowser ? 1200 : 800);
        setTimeout(() => scrollEntries(
          navigationDirection,
          descendingActiveSupersetArray[lastSupersetWorkoutOfSameSetsIndex].completed_sets - 1,
          lastWorkoutOfSameSetsIndex,
        ), 10);
        /*
          If there are no multiple workouts with the largest completed sets,
          and it's not the current workout, go to the largest workout.
        */
      } else {
        const isWorkoutWithLargestCompletedSets = (workout) => (
          descendingActiveSupersetArray[0].saveDataId === workout.saveDataId
        );
        const prevWorkoutIndex = data.findIndex(isWorkoutWithLargestCompletedSets);
        const prevWorkout = descendingActiveSupersetArray[0];
        const prevWorkoutSupersetIndex = (
          activeSupersetArray.findIndex(isWorkoutWithLargestCompletedSets)
        );
        console.log('Am I actually running?', prevWorkoutIndex, prevWorkout, prevWorkoutSupersetIndex);
        decreaseCompletedSets(namespace, activeSuperset, prevWorkoutSupersetIndex);
        selectWorkout(
          prevWorkout.saveDataId,
          prevWorkoutIndex,
          prevWorkout.exercise.id,
          prevWorkout.type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          prevWorkoutIndex,
        ), isSilkBrowser ? 1200 : 800);
        setTimeout(() => scrollEntries(
          navigationDirection,
          prevWorkout.completed_sets - 1,
          prevWorkoutIndex,
        ), 10);
      }
      setIsNavigatingSuperset(namespace, false);
    }
  };

  /*
    Run superset navigation logic if workoutsBySuperset was updated after
    incremented completed sets, and isNavigatingSuperset is true
  */
  useEffect(() => {
    if (isNavigatingSuperset) {
      if (isProgramView) {
        navigateSupersetProgram();
      } else {
        navigateSuperset();
      }
    }
  }, [isNavigatingSuperset]);

  const handleSupersetNavigation = (direction) => {
    console.log('Navigation Direction:', direction);
    if (direction === 'forward') {
      increaseCompletedSets(namespace, activeSuperset, activeSupersetWorkoutIndex);
      console.log('increased');
    }
    setIsNavigatingSuperset(namespace, true);
  };

  const handleActiveWorkout = (workoutId, index, exerId, exerType) => {
    const isWorkoutActive = activeWorkoutIndex === index;
    const saveWorkout = new Promise((resolve) => {
      if ((isFormik) && (activeWorkoutIndex !== null)) {
        if (formik[activeWorkoutIndex].current) {
          formik[activeWorkoutIndex].current.submitForm();
        } else {
          // console.log('This is why theres no current:', formik);
        }
        setTimeout(resolve('saved'), 9000);
      }
    });
    console.log(`Active Workout Is ${workoutId}, ${index}, ${exerId}, ${exerType}, ${isWorkoutActive}`);
    if (isWorkoutActive && isFormik) {
      saveWorkout.then(() => {
        setTimeout(() => closeWorkout(namespace), isSilkBrowser ? 700 : 500);
      });
    } else if (isWorkoutActive && !isFormik) {
      closeWorkout(namespace);
    } else {
      saveWorkout.then(
        setTimeout(() => selectWorkout(
          workoutId, index, exerId, exerType, namespace,
        )), isSilkBrowser ? 700 : 500,
      );
    }
    setTimeout(() => scrollWorkout(index), isSilkBrowser ? 1200 : 800);
  };

  // NEXT BUTTON

  const nextButton = () => {
    const isBeforeLastQuestion = (
      isScience
        ? activeQuestionIndex < (questionList[activeWorkoutIndex].current
          && (questionList[activeWorkoutIndex].current.props.workout.questions.length - 1))
        : false
    );

    const isLastWorkout = activeWorkoutIndex === (data.length - 1);

    if (isFormik && !wrvSupersetsEnabled) {
      if (formik[activeWorkoutIndex].current) {
        formik[activeWorkoutIndex].current.submitForm();
      }
    }

    if ((isScience) && (isBeforeLastQuestion)) {
      if (questionList[activeWorkoutIndex].current) {
        selectQuestion(
          parseInt(
            data[activeWorkoutIndex].questions[activeQuestionIndex + 1].id, 10,
          ),
          activeQuestionIndex + 1,
          namespace,
        );
      }
      setTimeout(() => scrollQuestions(
        activeQuestionIndex,
        activeQuestionIndex + 1,
      ), isSilkBrowser ? 1200 : 800);
    } else {
      if ((isScience) && (isLastQuestion)) {
        if (!isCompletionRequired) {
          restartQuestions(namespace);
          if (!isLastWorkout) {
            // If the next workout is a BR, expand it and skip to next workout
            if (data[activeWorkoutIndex + 1].type === 'BR') {
            // Find the next workout that isn't a BR and go to it
            // (in case the next BR is empty)
              let nextIndex;
              // eslint-disable-next-line no-plusplus
              for (let i = activeWorkoutIndex + 1, n = data.length - 1; i <= n; i++) {
                if (data[i].type !== 'BR') {
                  nextIndex = i;
                  break;
                }
              }
              // Open the next BR before scrolling
              if (data[nextIndex - 1].showSessionBreakItems === 0) {
                dispatch(setShowSessionBreakItems(namespace, nextIndex - 1, 1));
              }
              setTimeout(() => scrollWorkout(nextIndex), isSilkBrowser ? 1200 : 800);
            } else {
              setTimeout(() => scrollWorkout(activeWorkoutIndex + 1), isSilkBrowser ? 1200 : 800);
            }
          }
        }
      }

      if (wrvSupersetsEnabled && isInSuperset) {
        if (formik[activeWorkoutIndex].current) {
          formik[activeWorkoutIndex].current.submitForm().then(() => {
            setNavigationDirection('forward');
            setTimeout(() => handleSupersetNavigation('forward'), 100);
          });
        } else {
          setNavigationDirection('forward');
          setTimeout(() => handleSupersetNavigation('forward'), 100);
        }
      } else if (!isLastWorkout) {
        // If the next workout is a BR, set isExpanded to true and skip to next workout
        if (data[activeWorkoutIndex + 1].type === 'BR') {
          // Find the next workout that isn't a BR and go to it
          let nextIndex;
          // eslint-disable-next-line no-plusplus
          for (let i = activeWorkoutIndex + 1, n = data.length - 1; i <= n; i++) {
            if (data[i].type !== 'BR') {
              nextIndex = i;
              break;
            }
          }
          // Open the next BR before scrolling
          if (data[nextIndex - 1].showSessionBreakItems === 0) {
            dispatch(setShowSessionBreakItems(namespace, nextIndex - 1, 1));
          }
          if (workoutRef[nextIndex].current) {
            setTimeout(() => selectWorkout(
              parseInt(workoutRef[nextIndex].current.props.id, 10),
              parseInt(workoutRef[nextIndex].current.props.index, 10),
              parseInt(workoutRef[nextIndex].current.props.exerId, 10),
              workoutRef[nextIndex].current.props.exerType,
              namespace,
            ), isSilkBrowser ? 700 : 500);
            setTimeout(() => scrollWorkout(nextIndex), isSilkBrowser ? 1200 : 800);
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (workoutRef[activeWorkoutIndex + 1].current) {
            setTimeout(() => selectWorkout(
              parseInt(workoutRef[activeWorkoutIndex + 1].current.props.id, 10),
              parseInt(workoutRef[activeWorkoutIndex + 1].current.props.index, 10),
              parseInt(workoutRef[activeWorkoutIndex + 1].current.props.exerId, 10),
              workoutRef[activeWorkoutIndex + 1].current.props.exerType,
              namespace,
            ), isSilkBrowser ? 1000 : 800);
            setTimeout(() => scrollWorkout(activeWorkoutIndex + 1), isSilkBrowser ? 1200 : 1000);
          }
        }
      } else if (isLastWorkout && !isCompletionRequired) {
        setTimeout(() => completeWorkout(namespace), 500);
      }
    }
  };

  // PREVIOUS BUTTON

  const prevButton = () => {
    const isAfterFirstQuestion = (
      isScience
        ? activeQuestionIndex > 0
        : ''
    );

    if (isFormik && !wrvSupersetsEnabled) {
      if (formik[activeWorkoutIndex].current) {
        formik[activeWorkoutIndex].current.submitForm();
      }
    }

    if ((isScience) && (isAfterFirstQuestion)) {
      if (questionList[activeWorkoutIndex].current) {
        selectQuestion(
          parseInt(
            questionList[activeWorkoutIndex]
              .current.props.data[activeQuestionIndex - 1].id, 10,
          ),
          activeQuestionIndex - 1,
          namespace,
        );
      }
      setTimeout(() => scrollQuestions(
        activeQuestionIndex,
        activeQuestionIndex - 1,
      ), isSilkBrowser ? 1200 : 800);
    } else if (wrvSupersetsEnabled && isInSuperset) {
      if (formik[activeWorkoutIndex].current) {
        formik[activeWorkoutIndex].current.submitForm().then(() => {
          setNavigationDirection('backwards');
          setTimeout(() => handleSupersetNavigation('backwards'), 100);
        });
      } else {
        setNavigationDirection('backwards');
        setTimeout(() => handleSupersetNavigation('backwards'), 100);
      }
    } else if (workoutRef[activeWorkoutIndex - 1].current) {
      setTimeout(() => selectWorkout(
        parseInt(workoutRef[activeWorkoutIndex - 1].current.props.id, 10),
        parseInt(workoutRef[activeWorkoutIndex - 1].current.props.index, 10),
        parseInt(workoutRef[activeWorkoutIndex - 1].current.props.exerId, 10),
        workoutRef[activeWorkoutIndex - 1].current.props.exerType,
        namespace,
      ), isSilkBrowser ? 700 : 500);
      setTimeout(() => scrollWorkout(activeWorkoutIndex - 1), isSilkBrowser ? 1200 : 800);
    // If the previous workout is a BR, expand it and skip to next workout
    } else if (data[activeWorkoutIndex - 1].type === 'BR') {
      // Find the next workout that isn't a BR and go to it
      // (in case the next BR is empty)
      let prevIndex;
      // eslint-disable-next-line no-plusplus
      for (let i = activeWorkoutIndex - 1; i >= 0; i--) {
        if (data[i].type !== 'BR') {
          prevIndex = i;
          break;
        }
      }
      // Find the BR that the workout belongs to in order to open it
      let brIndex;
      // eslint-disable-next-line no-plusplus
      for (let j = prevIndex; j >= 0; j--) {
        if (data[j].type === 'BR') {
          brIndex = j;
          break;
        }
      }
      // Open the next BR before scrolling
      if (brIndex !== null && data[brIndex].showSessionBreakItems === 0) {
        dispatch(setShowSessionBreakItems(namespace, brIndex, 1));
      }
      if (workoutRef[prevIndex].current) {
        setTimeout(() => selectWorkout(
          parseInt(workoutRef[prevIndex].current.props.id, 10),
          parseInt(workoutRef[prevIndex].current.props.index, 10),
          parseInt(workoutRef[prevIndex].current.props.exerId, 10),
          workoutRef[prevIndex].current.props.exerType,
          namespace,
        ), isSilkBrowser ? 700 : 500);
        setTimeout(() => scrollWorkout(prevIndex), isSilkBrowser ? 1200 : 800);
      }
    }
  };

  // VARIABLES FOR JSX
  const workoutList = data;
  const hasWorkouts = workoutList.length > 0;
  const isWorkoutInactive = activeWorkout === null;
  const emptyFunction = useCallback(() => {}, []);
  const isLastWorkout = activeWorkoutIndex === (data.length - 1);
  const isLastSuperset = ((wrvSupersetsEnabled && isInSuperset)
    && (activeSupersetArray[activeSupersetWorkoutIndex].completed_sets
      >= activeSupersetArray[activeSupersetWorkoutIndex].sets - 1));

  // Ternary Conditions for navigation arrow buttons

  const completeIfIsLastSuperset = wrvSupersetsEnabled && isInSuperset
    ? isLastSuperset
    : true;

  const completeIfIsLastQuestion = isScience
    ? isLastQuestion
    : completeIfIsLastSuperset;

  const faceDownIfIsNotLastSuperset = wrvSupersetsEnabled && isInSuperset
    ? !isLastSuperset
    : false;

  const faceDownIfIsNotLastQuestion = isScience
    ? !isLastQuestion
    : faceDownIfIsNotLastSuperset;

  const checkmarkIfIsLastSuperset = isLastSuperset
    ? 'checkmark'
    : 'alt-up-arrow';

  const checkIfSuperset = (wrvSupersetsEnabled && isInSuperset)
    ? checkmarkIfIsLastSuperset
    : 'checkmark';

  const unlockIfIsLastQuestion = isLastQuestion && isCompletionRequired
    ? 'lock'
    : 'checkmark';

  const checkmarkIfIsLastQuestion = isLastQuestion
    ? unlockIfIsLastQuestion
    : 'alt-up-arrow';

  const checkmarkIfIsScienceandLastQuestion = isScience
    ? checkmarkIfIsLastQuestion
    : checkIfSuperset;

  // Collapsible Session Break Logic
  let renderedComponents;
  if (workoutList.length > 0) {
    // Keep track of items already rendered
    const renderedItems = new Set(); // Keep track of items already rendered
    // Map through the workout array and generate components
    renderedComponents = workoutList.map((item, index) => {
      if (item.type === 'BR') {
        const endIndex = workoutList.findIndex((obj, i) => i > index && obj.type === 'BR');

        const subArray = workoutList
          .slice(index + 1, endIndex !== -1 ? endIndex : undefined)
          .map((obj, i) => ({ ...obj, originalIndex: index + 1 + i }));

        // Mark subArray items as rendered
        subArray.forEach((subItem) => {
          renderedItems.add(subItem.assignedId || subItem.saveDataId);
        });

        return (
          <WeightroomSessionBreakContainer
            sessionItems={subArray}
            sessionBreak={item}
            activeWorkoutIndex={activeWorkoutIndex}
            entriesContainerRef={entriesContainerRef}
            entryRowRef={entryRowRef}
            formikRef={formik}
            handleActiveWorkout={handleActiveWorkout}
            index={index}
            key={item.assignedId || item.saveDataId}
            namespace={namespace}
            nextButton={item.type === 'R' ? nextButton : emptyFunction}
            questionListRef={questionList}
            scrollQuestions={item.type === 'S' ? scrollQuestions : emptyFunction}
            supersetIndex={activeSupersetWorkoutIndex}
            workoutRef={workoutRef}
            workoutWrapperRef={workoutWrapperRef}
            workoutInnerRef={workoutInner}
          />
        );
      }
      // Skip rendering if item already rendered or in subArray
      if (!renderedItems.has(item.assignedId || item.saveDataId)) {
        renderedItems.add(item.assignedId || item.saveDataId);
        return (
          <WeightroomWorkout
            activeWorkoutIndex={activeWorkoutIndex}
            entriesContainerRef={entriesContainerRef[index]}
            entryRowRef={entryRowRef[index]}
            formikRef={formik[index]}
            handleActiveWorkout={handleActiveWorkout}
            index={index}
            key={item.assignedId || item.saveDataId}
            namespace={namespace}
            nextButton={item.type === 'R' ? nextButton : emptyFunction}
            questionListRef={questionList[index]}
            scrollQuestions={item.type === 'S' ? scrollQuestions : emptyFunction}
            supersetIndex={activeSupersetWorkoutIndex}
            workoutRef={workoutRef[index]}
            workoutWrapperRef={workoutWrapperRef[index]}
            workoutInnerRef={workoutInner[index]}
          />
        );
      }
      return null;
    });
  }

  return (
    <>
      <WorkoutsContainer
        activeScreens={activeScreens}
        id='workouts-container'
        ref={workoutsContainer}
        className={`${activeWorkout ? 'active' : ''}`}
        fullHeight={isWorkoutInactive}
      >
        {
          hasWorkouts && renderedComponents
            ? (
              renderedComponents
            ) : (
              <MessageContainer>
                <HeaderText>{workoutsError === '' ? 'No Workouts for Selected Date' : workoutsError}</HeaderText>
              </MessageContainer>
            )
        }
      </WorkoutsContainer>

      { isWorkoutActive && (
      <WorkoutNav>
        <ArrowButton
          handleArrowClick={prevButton}
          icon='alt-up-arrow'
          disabled={
              ((!wrvSupersetsEnabled && activeExerType !== 'S') && activeWorkoutIndex === 0)
              || ((!wrvSupersetsEnabled && activeWorkoutIndex === 0) && (activeExerType === 'S' && activeQuestionIndex === 0))
              || ((wrvSupersetsEnabled && isInSuperset)
                  && (activeWorkoutIndex === 0 && activeSupersetArray[0].completed_sets === 0))
              || ((wrvSupersetsEnabled && !isInSuperset && activeExerType !== 'S') && activeWorkoutIndex === 0)
              || ((wrvSupersetsEnabled && activeWorkoutIndex === 0) && (activeExerType === 'S' && activeQuestionIndex === 0))
            }
          large
          light
        />
        <ArrowButton
          complete={
              isLastWorkout ? (
                completeIfIsLastQuestion
              ) : (
                false
              )
            }
          down={
              isLastWorkout ? (
                faceDownIfIsNotLastQuestion
              ) : (
                true
              )
            }
          handleArrowClick={nextButton}
          icon={
              isLastWorkout ? (
                checkmarkIfIsScienceandLastQuestion
              ) : (
                'alt-up-arrow'
              )
            }
          large
          light
        />
      </WorkoutNav>
      )}
    </>
  );
};

WorkoutList.propTypes = {
  completeWorkout: PropTypes.func.isRequired,
  closeWorkout: PropTypes.func.isRequired,
  decreaseCompletedSets: PropTypes.func.isRequired,
  increaseCompletedSets: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  restartQuestions: PropTypes.func.isRequired,
  selectQuestion: PropTypes.func.isRequired,
  selectWorkout: PropTypes.func.isRequired,
  setIsNavigatingSuperset: PropTypes.func.isRequired,
};

export default memo(WorkoutList);
